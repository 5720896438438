<template>
	<div class="main-contents point manage">
		<div class="tit">
			수요기업 포인트관리
			<img class="shortcut" src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
			<img src="/images/admin/points.png" alt="포인트"/>
		</div>
		<div class="member_point">{{data.corpNm}} 님의 포인트 관리</div>
		<div class="mem_num"><img src="/images/admin/logo_sm.png" alt="하이프로로고"/> 기업번호 <span class="num">{{data.corpSeq}}</span></div>
		<div class="point_banner">
			<img src="/images/admin/point_banner.png" alt="포인트관리"/>
			<div class="image_box">
				<ImageComp :src="'/api/mem/myp/corpImageDown?corpSeq=' + input.saveCorpSeq" defaultSrc="COMPANY" />
			</div>
			<div class="customer">{{data.corpNm}}</div>
			<div class="point">
				<span>{{data.corpNm}}</span>의 적립포인트는<br/>
				<span class="pt">{{data.pointSum | number}}</span> <img src="/images/admin/point_m.png" alt="포인트"/> 입니다.
			</div>
		</div>
		<div class="filter">
			<div class="filter_box">
				<ul>
					<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'regiDsc'}"/><p @click="changeOrder('regiDsc')">최근 등록 순</p><span>ㅣ</span></li>
					<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'incDsc' }"/><p @click="changeOrder('incDsc' )">적립포인트 많은 순</p><span>ㅣ</span></li>
					<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'incAsc' }"/><p @click="changeOrder('incAsc' )">적립포인트 적은 순</p><span>ㅣ</span></li>
					<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'decDsc' }"/><p @click="changeOrder('decDsc' )">차감포인트 많은 순</p><span>ㅣ</span></li>
					<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'decAsc' }"/><p @click="changeOrder('decAsc' )">차감포인트 적은 순</p></li>
				</ul>
			</div>
		</div>
		<!-- 적립포인트 리스트 -->
		<div class="Board type3 point">
			<table class="Board_type3">
				<colgroup>
					<col width="6%">
					<col width="18%">
					<col width="27%">
					<col width="8%">
					<col width="12%">
					<col width="8%">
					<col width="7%">
					<col width="7%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>적립구분</th>
						<th>적립내용</th>
						<th>날짜</th>
						<th>적립포인트</th>
						<th>확정여부</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!list || list.length == 0"><td colspan="8" class="none">적립된 포인트가 없습니다!</td></tr>
					<tr v-for="(item, idx) in list" :key="idx" v-else>
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (idx + 1)}}</td>
						<td class="score">{{item.pointSaveItemNm}}</td>
						<td><img src="/images/admin/new.png" alt="알림" v-if="isNew(item)"/> {{item.pointSaveDtlCont || item.pointSaveItemDesc}}</td>
						<td class="score">{{item.regDate | date('yyyy. MM. dd')}}</td>
						<td class="score amount">{{item.pointAmt | number}} <img src="/images/admin/point_s.png" alt="적립포인트"/></td>
						<td class="score cf">
							<p class="ud" v-if="item.pointCfmYn == 'N'">미 확 정</p>
							<p class="ud" v-else-if="item.pointCfmYn == 'C'">취소확정</p>
							<p class="cf" v-else-if="item.pointSaveDivCd == '01'">+ 확 정</p>
							<p v-else-if="item.pointSaveDivCd == '02'">- 취 소</p>
							<p class="cf" v-else-if="item.pointSaveDivCd == '03'">- 지 급</p>
							<p v-else-if="item.pointSaveDivCd == '04'">- 차 감</p>
						</td>
						<template v-if="item.pointCfmYn == 'N'">
							<td class="score"><img class="btn" src="/images/admin/point_btn_01.png" alt="확정" @click="setPointCfmYn(item, 'Y')"/></td>
							<td class="score"><img class="btn" src="/images/admin/point_btn_02.png" alt="취소" @click="setPointCfmYn(item, 'C')"/></td>
						</template>
						<template v-else-if="item.pointCfmYn == 'Y'">
							<template v-if="item.pointSaveDivCd == '01' && !item.nextTranDivCd">
								<td class="score"><img class="btn" src="/images/admin/point_btn_03.png" alt="차감" @click="cancelPointSave(item)"/></td>
							</template>
						</template>
					</tr>
				</tbody>
			</table>
		</div>
		
		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>

		<!-- 포인트 확정 -->
		<div class="point_cf">
			<p>확정</p><span>{{data.pointSaveSum | number}}</span>
		</div>
		<div class="point_cf pay">
			<p>지급 포인트</p><span>-{{data.pointPaySum | number}}</span>
			<p>차감 포인트</p><span>-{{data.pointCancelSum + data.pointDecSum | number}}</span>
		</div>
		<div class="point_cf total">
			<p>현재</p><span>{{data.pointSum | number}}</span><img src="/images/admin/point_m.png" alt="포인트"/>
		</div>
		<!-- 하단 적립내용 검색부분 -->
		<div class="search-box point">
			<SelectComp title="적립구분" v-model="saveInput.pointSaveCd" :list="cdList" codeKey="pointSaveCd" nameKey="pointSaveItemNm" @change="changePointSaveCd" :showError="showError" rules="required"/><nbsp/>
			<InputComp type="text" title="적립내용" v-model="saveInput.pointSaveDtlCont" ref="pointSaveDtlCont"/><nbsp/>
			<InputComp type="number" title="적립포인트" v-model="saveInput.pointSaveAmt" placeholder="적립포인트(숫자만) 입력" :showError="showError" rules="required" :disabled="disableAmt()"/>
			<img src="/images/admin/point_l.png" alt="적립금"/>
			<div class="btn" @click="save">실행</div>
		</div>
	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
export default {
	components : { PagingComp },
	data() {
		return {
			input: {
				pointSaveTargetDivCd: 'D',
				saveCorpSeq: this.$route.query.corpSeq,
				orderGubun: 'regiDsc',
				pageIndex : '1',
			}, // 검색조건
			saveInput: {
				type: 'CORP',
				pointSaveDivCd: '',
				corpNm: '',

				saveCorpSeq: this.$route.query.corpSeq,
				pointSaveCd: '',
				pointSaveAmt: 0,
				pointSaveDtlCont: '',
				preTranHistorySeq: '',
			},
			data: {},
			list : [],
			pageInfo : {},
			cdList: [],
			showError: false,
		};
	},
	mounted() {
		// console.log('mounted');
		var pointSaveTargetDivCd = this.input.pointSaveTargetDivCd;
		this.$.httpPost('/api/mem/pit/getPointSavePolicy', { pointSaveTargetDivCd })
			.then(res => { 
				for(var i in res.data.list){
					//적립취소를 제외하고..
					if(res.data.list[i].pointSaveDivCd != '02'){
						this.cdList.push(res.data.list[i]);
					}
				}
			 }).catch(this.$.httpErrorCommon);
		this.getList();
	},
	methods: {
		getList() {
			if(this.input.saveCorpSeq){
				this.$.httpPost('/api/mem/pit/getCorpPoint', this.input)
					.then(res => {
						this.data = res.data;
						this.list = res.data.list;
						this.pageInfo = res.data.pageInfo;
						this.saveInput.corpNm = this.data.corpNm;
					}).catch(this.$.httpErrorCommon);
			}
		},
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},
		changeOrder(orderGubun){
			//console.log('changeOrder', orderGubun, this.input.orderGubun)
			if(orderGubun != this.input.orderGubun){
				this.input.orderGubun = orderGubun;
				this.goPage(1);
			}
		},

		save() {
			if(this.$.validate(this.saveInput) === true){
				this.$.popup('/adm/mem/MEM912P06', this.saveInput)
					.then(res => { if(res) this.goPage(1); }).catch(this.$.httpErrorCommon);
			} else {
				this.showError = true;
			}
		},
		setPointCfmYn(item, pointCfmYn){
			var pointSaveHistorySeq = item.pointSaveHistorySeq;
			this.$.httpPost('/api/mem/pit/setPointCfmYn', {pointSaveHistorySeq, pointCfmYn})
				.then(() => { this.goPage(1); }).catch(this.$.httpErrorCommon);
		},
		cancelPointSave(item){
			if(item){
				this.$set(this.saveInput, 'pointSaveCd'		, item.pointSaveCd.substr(0, 2) + '4000');
				this.$set(this.saveInput, 'pointSaveAmt'	, item.pointAmt);
				this.$set(this.saveInput, 'pointSaveDtlCont', '[' + (item.pointSaveDtlCont || item.pointSaveItemDesc) + '] 차감');
				this.$set(this.saveInput, 'pointSaveDivCd'	, '04');
				this.$set(this.saveInput, 'preTranHistorySeq', item.pointSaveHistorySeq);

				this.$refs.pointSaveDtlCont.$refs.input.focus();
			}
			/*
			var pointSaveHistorySeq = item.pointSaveHistorySeq;
			var pointSaveTargetDivCd = this.input.pointSaveTargetDivCd;
			this.$.httpPost('/api/mem/pit/cancelPointSave', { pointSaveHistorySeq, pointSaveTargetDivCd })
				.then(() => { this.goPage(1); }).catch(this.$.httpErrorCommon);*/
		},

		isNew(item){
			return item.pointCfmYn == 'N';
		},

		changePointSaveCd(event, val, item){
			//console.log('changePointSaveCd', event, val, item)
			if(item){
				this.$set(this.saveInput, 'pointSaveAmt'	, item.pointSaveAmt);
				this.$set(this.saveInput, 'pointSaveDtlCont', item.pointSaveItemDesc);
				this.$set(this.saveInput, 'pointSaveDivCd'	, item.pointSaveDivCd);
				this.$set(this.saveInput, 'preTranHistorySeq', '');
			}
		},

		disableAmt(){
			return ['PD1000', 'PD2000', 'PD3000', 'PD4000'].indexOf(this.saveInput.pointSaveCd) < 0 || this.saveInput.preTranHistorySeq != '';
		}
	},
};
</script>
